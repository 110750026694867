import axios from 'axios';
import { mostRecentStore } from '../../mostRecentStore';
import Podcast from '../Podcast/Podcast';
import HorizontalItemList from '../HorizontalItemList';
import { useEffect, useState } from 'preact/hooks';

interface PodcastsApiResponse {
  data: Podcast[];
}
interface Podcast {
  id: string;
  title: string;
  summary: string;
  images: Images;
  genre: string;
  url: string;
}
interface Images {
  large: string;
  small: string;
  medium: string;
  mini: string;
  srcset: string;
}

function getPodcasts(ids: number[] | string[]) {
  return axios.get<PodcastsApiResponse>(`/api/podcasts?ids=${ids.join(',')}`);
}

export default function MostRecent({ title }: { title: string }) {
  const [loading, setLoading] = useState(false);
  const [podcasts, setPodcasts] = useState<PodcastsApiResponse['data']>([]);

  function fetchData() {
    const ids = mostRecentStore.load().getRecents();

    getPodcasts(ids).then(({ data }) => {
      setPodcasts(data.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section class="items-grid t-most-recent">
      <div class="my-4">
        <h2 class="medium-title my0">{title}</h2>
      </div>

      <div class="row">
        <HorizontalItemList horizontalScroll={true}>
          {loading
            ? Array.from(Array(10)).map(() => (
                <div className="media-block">
                  <div class="media-box ratio-container ratio1x1" />
                </div>
              ))
            : null}
          {podcasts.map((podcast) => (
            <Podcast
              key={podcast.url}
              url={podcast.url}
              title={podcast.title}
              imageSrc={podcast.images.medium}
              genre={podcast.genre}
            />
          ))}
        </HorizontalItemList>
      </div>
    </section>
  );
}
