let m = new Set();

export default function inView(nodes, options = {}) {
  if (typeof nodes === 'string') {
    nodes = document.querySelectorAll(nodes);
  } else if (nodes instanceof Node) {
    nodes = [nodes];
  }
  function createObserver(cbs) {
    const { once = false, leave = () => {}, enter = () => {} } = cbs;
    let visible = new Set();
    let observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            visible.add(entry.target);
            enter(entry.target);
          } else if (
            entry.intersectionRatio <= 0 &&
            visible.has(entry.target)
          ) {
            leave(entry.target);
            visible.delete(entry.target);
            if (once) {
              observer.unobserve(entry.target);
            }
          }
        });
      },
      {
        root: null,
        rootMargin: '-30px',
        threshold: [0],
        ...options,
      }
    );
    m.add(observer);

    nodes.forEach((n) => {
      observer.observe(n);
    });
  }

  const api = {
    on: (event, cb) => {
      createObserver({ [event]: cb, once: false });
      return api;
    },
    once: (event, cb) => {
      createObserver({ [event]: cb, once: true });
      return api;
    },
  };
  return api;
}
