import lStore from 'store';

const trimAndStack = (item: string, stack: string[]) => {
  stack.splice(24, 1);
  stack.unshift(item);
};

type Store = {
  visited: string[];
  load: () => Store;
  trackVisit: (id: string) => void;
  getRecents: () => string[];
  STORAGE_NAMESPACE: string;
};


export const mostRecentStore = {
  STORAGE_NAMESPACE: 'recent:visited',
  visited: [],
  trackVisit(id: string) {
    if (this.visited.includes(id)) {
      this.visited.splice(this.visited.indexOf(id), 1);
    }
    trimAndStack(id, this.visited);
    lStore.set(this.STORAGE_NAMESPACE, this.visited);
  },
  getRecents() {
    return this.visited.splice(0, 10);
  },
  load() {
    this.visited = lStore.get(this.STORAGE_NAMESPACE) || [];
    return this;
  },
} satisfies Store;
