import axios from 'axios';
import HorizontalItemList from './HorizontalItemList';
import Podcast from './Podcast/Podcast';
import CustomAd from './CustomAd/CustomAd';
import { useEffect, useState } from 'preact/hooks';

const slot = 'popular.horizontal';

interface PopularPodcastsResponse {
  data: Popular[];
}

interface Popular {
  url: string;
  title: string;
  genre: string;
  images: Images;
}

interface Images {
  large: string;
  small: string;
  medium: string;
  mini: string;
  srcset: string;
}

interface AdResponse {
  data: Ad;
}

interface Ad {
  title: string;
  categoryName: string;
  meta: Meta;
  images: Images;
  link: string;
  id: number;
}

interface Meta {
  title: string;
}

const actions = {
  async fetchPopular() {
    return await axios.get<PopularPodcastsResponse>('/api/podcasts/popular');
  },
  async fetchAd(excludeIds?: string) {
    const params = new URLSearchParams({
      ...(excludeIds ? { exclude: excludeIds } : {}),
      s: slot,
    });
    return await axios.get<AdResponse>(`/api/ad?${params.toString()}`);
  },
};

async function created(excludeIds?: string) {
  const result: {
    error: null;
    data: {
      popular: Popular[] | null;
      ad: Ad | null;
    };
  } = {
    error: null,
    data: {
      popular: null,
      ad: null,
    },
  };

  const [popularPodcasts, ad] = await Promise.allSettled([
    actions.fetchPopular(),
    actions.fetchAd(excludeIds),
  ]);

  if (popularPodcasts.status === 'fulfilled') {
    result.data.popular = popularPodcasts.value.data.data.filter((x) => {
      if (!result.data.ad) { return true; }
      return result.data.ad.link !== x.url;
    });
  }

  if (ad.status === 'fulfilled' && ad.value.data.data !== null) {
    result.data.ad = { ...ad.value.data.data };
  }

  return result;
}

export default function PopularPodcasts({
  title,
  action,
}: {
  title: string;
  action: string;
}) {
  const [podcasts, setPodcasts] = useState<Popular[] | null>();
  const [ad, setAd] = useState<Ad | null>(null);
  const [loading, setLoading] = useState(true);
  const podcast = window.__INITIAL_STATE?.podcast;

  useEffect(() => {
    created(podcast ? podcast.itunes_id : undefined)
      .then((result) => {
        setPodcasts(result.data.popular);
        setAd(result.data.ad);
        setLoading(() => false);
      })
      .catch(() => {
        setLoading(() => false);
      });
  }, []);

  function refetchAd() {
    actions.fetchAd(podcast ? podcast.itunes_id : undefined).then((result) => {
      setAd(result.data.data);
    });
  }

  return (
    <section class="items-grid t-most-recent">
      <div class="my-4">
        {action ? (
          <a href={action} class="my0">
            <h2 class="medium-title my0">{title}</h2>
          </a>
        ) : (
          <h2 class="medium-title my0">{title}</h2>
        )}
      </div>

      <div class="row">
        <HorizontalItemList horizontalScroll={true}>
          {loading
            ? Array.from(Array(10)).map(() => (
                <div className="media-block">
                  <div class="media-box ratio-container ratio1x1" />
                </div>
              ))
            : null}
          {podcasts?.map((podcast, index) => (
            <>
              <Podcast
                key={podcast.url}
                url={podcast.url}
                title={podcast.title}
                imageSrc={podcast.images.medium}
                genre={podcast.genre}
              />
              {ad && index === 0 ? (
                <CustomAd
                  url={ad.link}
                  pid={ad.id}
                  src={ad.images.medium}
                  title={ad.title}
                  slogan={ad.meta.title}
                  refetchAd={refetchAd}
                  slotid={slot}
                />
              ) : null}
            </>
          ))}
        </HorizontalItemList>
      </div>
    </section>
  );
}
