import c from 'clsx';
import type { ComponentChildren } from 'preact';

type Horizontal = { horizontal: true; horizontalScroll?: false };
type HorizontalScroll = { horizontalScroll: true; horizontal?: false };

type Props = { children: ComponentChildren } & (Horizontal | HorizontalScroll);

export default function HorizontalItemList({
  horizontal,
  horizontalScroll,
  children,
}: Props) {
  return (
    <div
      class={c(
        'media-block-container',
        horizontal && 'media-block-container--horizontal',
        horizontalScroll && 'media-block-container--horizontal-scroll'
      )}
    >
      {children}
    </div>
  );
}
