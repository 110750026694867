import { createRef } from 'preact';
import { useEffect } from 'preact/hooks';
import createObserver from '../../utils/adobserver';

type CustomAdProps = {
  url: string;
  title: string;
  src: string;
  pid: number;
  slotid: string;
  slogan: string;
  refetchAd: () => void;
};

export default function CustomAd({
  title,
  slogan,
  pid,
  url,
  src,
  slotid,
  refetchAd,
}: CustomAdProps) {
  const ref = createRef();

  const urlWithTracking = () => {
    const urlParams = new URLSearchParams({
      pid: pid.toString(),
      s: slotid,
    });
    return `${url}?${urlParams.toString()}`;
  };

  useEffect(() => {
    const unobserve = createObserver(
      { el: ref.current, pid, slot: slotid },
      {
        hooks: {
          onReplace: () => {
            refetchAd();
          },
        },
      }
    );
    return () => {
      unobserve();
    };
  }, [pid]);

  return (
    <a ref={ref} data-pid={pid} href={urlWithTracking()} class="media-block">
      <div class="media-box ratio-container ratio1x1">
        <img
          class="media-box__image lazyload"
          key="pid"
          alt={title}
          data-src={src}
        />
      </div>
      <div class="media-block__meta">
        <h2 class="media-block__title">{title}</h2>
        <div class="text-sm uppercase mb-1 font-semibold text-primaryHighlight">
          {slogan}
        </div>
      </div>
      <div class="media-block__go">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
            fill="#aaaaaa"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </div>
    </a>
  );
}
